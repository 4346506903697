import { ComputerDesktopIcon } from "@heroicons/react/24/outline";
import { SiAndroid, SiApple, SiLinux } from "@icons-pack/react-simple-icons";
import BasicSection from "components/BasicSection";
import {
    AppStoreButton,
    FDroidButton,
    PlayStoreButton,
} from "components/DownloadButtons";
import Layout from "components/Layout";
import ExternalLink from "components/links/ExternalLink";
import { DownloadFormat, DownloadURL } from "constants/download";
import { PageProps } from "gatsby";
import React, {
    HTMLAttributes,
    PropsWithChildren,
    useEffect,
    useState,
} from "react";
import styled from "styled-components";
import {
    fallbackDesktopArtifact,
    fetchArtifacts,
    getFormat,
    IArtifact,
} from "utils/download";
import { isMobile } from "react-device-detect";
import { WindowIcon } from "@heroicons/react/24/solid";

const Download: React.FC<PageProps> = ({ path, params }) => {
    // This pages is named as a splat route (`[...].tsx`), so anything that
    // comes after the `/download/` path will be available in the `params` under
    // the `*` key.
    const formatString = params["*"];
    const format = getFormat(formatString);

    const [artifacts, setArtifacts] = useState<IArtifact[]>();

    const showArtifactsAndStartDownload = (artifacts: IArtifact[]) => {
        // Set the list of artifacts to render on the page
        setArtifacts(artifacts);
        // But also trigger an automatic download if we were asked to
        if (format) {
            const artifact = artifacts.find((x) => x.format === format);
            if (artifact) {
                window.location.href = artifact.url;
            }
        }
    };

    useEffect(() => {
        fetchArtifacts().then(showArtifactsAndStartDownload);
    }, []);

    return (
        <Layout title="Ente - Download" path={path} shouldShowDownloads={false}>
            <BasicSection>
                <BasicSection.Header>
                    <BasicSection.Heading>Download</BasicSection.Heading>
                </BasicSection.Header>

                <BasicSection.BodyElevated>
                    <div className="d-flex flex-column gap-5">
                        {format && <AutomaticDownloadNotice />}
                        {artifacts ? (
                            <DownloadOptions artifacts={artifacts} />
                        ) : (
                            <LoadingSpinner />
                        )}
                    </div>
                </BasicSection.BodyElevated>
            </BasicSection>
        </Layout>
    );
};

export default Download;

const AutomaticDownloadNotice: React.FC = () => {
    return (
        <p className="text-center mt-0">
            Your download should've automatically begun. If not, please select
            one of the download options from below.
        </p>
    );
};

const LoadingSpinner: React.FC = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-muted">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

interface ArtifactListProps {
    artifacts: IArtifact[];
}

const DownloadOptions: React.FC<ArtifactListProps> = ({ artifacts }) => {
    return (
        <div>
            {isMobile ? <OptionsMobile {...{ artifacts }} /> : <></>}
            <div className="d-flex flex-column flex-md-row justify-content-around gap-4">
                <OptionsWindows {...{ artifacts }} />
                <OptionsMacOS {...{ artifacts }} />
            </div>
            <div className="d-flex justify-content-around py-md-4">
                <OptionsLinux {...{ artifacts }} />
            </div>
            {!isMobile ? <OptionsMobile {...{ artifacts }} /> : <></>}
        </div>
    );
};

const OptionsMobile: React.FC<ArtifactListProps> = ({ artifacts }) => {
    const apkArtifact = artifacts.find(
        (artifact) => artifact.format == DownloadFormat.APK,
    );
    return (
        <div className="d-flex flex-column flex-md-row justify-content-around gap-4">
            <div className="d-flex flex-column align-items-center gap-3 px-5">
                <PlatformName>Mobile</PlatformName>
                <AppStoreButton link={DownloadURL.appStore} />
                <PlayStoreButton link={DownloadURL.googlePlay} />
                <FDroidButton link={DownloadURL.fDroid} />
            </div>
            {apkArtifact && <OptionsAndroidAPK {...{ apkArtifact }} />}
        </div>
    );
};

const OptionsAndroidAPK: React.FC<{ apkArtifact: IArtifact }> = ({
    apkArtifact,
}) => {
    return (
        <div className="d-flex flex-column align-items-center px-5">
            <PlatformName>Android APK</PlatformName>
            <div className="d-flex flex-column align-items-center gap-0">
                <Artifact {...apkArtifact} />
                <SupportsAutoUpdates />
            </div>
        </div>
    );
};

const PlatformName: React.FC<PropsWithChildren<HTMLAttributes<{}>>> = ({
    className,
    children,
}) => {
    return (
        <h3 className={"text-muted mb-4 " + `${className ?? ""}`}>
            {children}
        </h3>
    );
};

const PlatformText: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <p className="text-center text-muted" style={{ maxWidth: "25rem" }}>
            <small>{children}</small>
        </p>
    );
};

const SupportsAutoUpdates: React.FC = () => {
    return <PlatformText>supports auto updates</PlatformText>;
};

const OptionsLinux: React.FC<ArtifactListProps> = ({ artifacts }) => {
    const appImageArtifact =
        artifacts.find(
            (artifact) => artifact.format == DownloadFormat.AppImage,
        ) ?? fallbackDesktopArtifact;

    const otherFormatsURL = fallbackDesktopArtifact.url;

    return (
        <div className="d-flex flex-column align-items-center px-5">
            <PlatformName>Linux</PlatformName>
            <Artifact {...appImageArtifact} />

            <div>
                <PlatformText>
                    Linux AppImage supports auto updates.
                </PlatformText>
                <PlatformText>
                    We also provide auto updating{" "}
                    <ExternalLink href={otherFormatsURL}>
                        RPM / DEB packages
                    </ExternalLink>
                    .
                </PlatformText>
            </div>
        </div>
    );
};

const OptionsWindows: React.FC<ArtifactListProps> = ({ artifacts }) => {
    const artifact =
        artifacts.find((artifact) => artifact.format == DownloadFormat.EXE) ??
        fallbackDesktopArtifact;

    return (
        <div className="d-flex flex-column align-items-center px-5">
            <PlatformName className="mb-4">Windows</PlatformName>
            <Artifact {...artifact} />
            <SupportsAutoUpdates />
        </div>
    );
};

const OptionsMacOS: React.FC<ArtifactListProps> = ({ artifacts }) => {
    const artifact =
        artifacts.find((artifact) => artifact.format == DownloadFormat.DMG) ??
        fallbackDesktopArtifact;

    return (
        <div className="d-flex flex-column align-items-center px-5">
            <PlatformName className="mb-4">macOS</PlatformName>
            <Artifact {...artifact} />
            <SupportsAutoUpdates />
        </div>
    );
};

const Artifact: React.FC<IArtifact> = ({ format, name, url }) => {
    return (
        <ArtifactLink href={url} title={`Download ${name}`}>
            {getIcon(format)}
            <div className="text-center">
                <div>{`Download ${name}`}</div>
            </div>
        </ArtifactLink>
    );
};

const ArtifactLink = styled.a`
    width: 220px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: between;
    gap: 1rem;

    text-decoration: none;
    color: var(--bs-body-color);

    &&:hover {
        color: var(--color-primary-500);
    }
`;

const getIcon = (format: DownloadFormat) => {
    const size = 128;
    switch (format) {
        case DownloadFormat.AppImage:
        case DownloadFormat.DEB:
        case DownloadFormat.RPM:
            return <SiLinux size={size} />;
        case DownloadFormat.DMG:
            return <SiApple size={size} />;
        case DownloadFormat.EXE:
            return <WindowIcon width={size} />;
        case DownloadFormat.Desktop:
            return <ComputerDesktopIcon width={size} />;
        case DownloadFormat.APK:
            return <SiAndroid size={size} />;
    }
};
